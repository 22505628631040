import s from "./Image.module.css";

class Image {
	constructor(src, text) {
		this.src = src;
		this.text = text;
	}

	getSrc() {
		return this.src;
	}

	getImage(isMobile) {
		return (
			<div className={isMobile ? s.boxMobile : s.box}>
				{/*eslint-disable-next-line*/}
				<img
					className={isMobile ? s.imageMobile : s.image}
					src={this.src}
				/>
				<div>{this.text}</div>
			</div>
		);
	}

	getThumbnail() {
		// eslint-disable-next-line
		return <img className={s.thumbnail} src={this.src} />;
	}
}

export default Image;
