import { useState } from "react";
import s from "./OtherTabs.module.css";
import MountainRadio from "../pages/MountainRadio";
import ISS from "./ISS";


function Dashboards() {
	const [dashboard, setDashboard] = useState(0);

	function getClass(id) {
		return dashboard === id ? s.tabSelected : s.tab;
	}

	function getDashboardClass(id) {
		return dashboard === id ? s.dashboard : s.dashboardHidden;
	}

	function getDashboardClass2(id) {
		return dashboard === id ? s.dashboard2 : s.dashboardHidden;
	}

	return (
		<div className={s.Dashboards}>
			<div className={s.topBar}>
				<div onClick={() => setDashboard(0)} className={getClass(0)}>
					International <br/>
					Space Station
				</div>
				<div onClick={() => setDashboard(1)} className={getClass(1)}>
					Mountain <br/> 
					Radio 
				</div>
				<div onClick={() => setDashboard(2)} className={getClass(2)}>
					Coming <br/>
					Soon
				</div>
				<div onClick={() => setDashboard(3)} className={getClass(3)}>
					Coming <br/>
					Soon
				</div> 
			</div>

			<div className={s.container}>
				<div
					className={getDashboardClass(0)}
				><ISS/></div>

				<div
					className={getDashboardClass(1)}
				><MountainRadio/></div>

				<div
					className={getDashboardClass(2)}
				>Coming soon</div>

				<div
					className={getDashboardClass(3)}
				>Coming soon</div>

				
			</div>
		</div>
	);
}

export default Dashboards;
