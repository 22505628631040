import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function Home() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Canterbury DMR Network</div>
			<br />
			<br />
			The DMR network is open for all Amateur Radio Operators to use in
			New Zealand.
			<br /> <br />
			There are 5 Multi Mode digital repeaters in Canterbury area of New
			Zealand running on the Brandmeister Network. <br /> <br /> There is
			mobile Coverage from Greta Valley to Pukeuri (North of Oamaru).
			<br />
			<br />
			<strong>
				For all your technical support, radio code plugs, hotspot
				configuration, network configuration, Pi Star, WPSD and all
				other digital modes please visit the{" "}
				<a
					className={s.link}
					target="_blank"
					href="https://www.facebook.com/share/1oK9HdAyoH5EwQtF/?mibextid=A7sQZp"
				>
					ZL5303 facebook page
				</a>
				. This is a good resource to solve all your problems and make it
				easy to get on to the DMR network.
			</strong>
			<br />
			<br />
			<img className={s.image} src={require("../images/tm9300.JPG")} />
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default Home;
