import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyAJDoP5KQZogq4RpJsV3B4u8BsOIJUb5nI",
  authDomain: "digitalradio-61904.firebaseapp.com",
  projectId: "digitalradio-61904",
  storageBucket: "digitalradio-61904.appspot.com",
  messagingSenderId: "576851580848",
  appId: "1:576851580848:web:a9bcc79ce404aad1bf2875",
  measurementId: "G-PV1LJJMQDN"
};
   
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

const database = firebaseApp.database("https://digitalradio-61904-default-rtdb.asia-southeast1.firebasedatabase.app");
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export { auth, database, googleProvider};