import { useEffect, useState } from "react";
import s from "./Dashboards.module.css";

import Dashboard from "../components/Dashboard";
import axios from "axios";

function formatDate(d) {
	var months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	let date = new Date(d);
	let dateArr = date.toLocaleDateString("en-US").split("/");

	if (dateArr.length < 3) {
		return "Error";
	}

	return (
		months[Number(dateArr[0]) - 1] +
		" " +
		dateArr[1] +
		" " +
		dateArr[2] +
		" " +
		date.toLocaleTimeString()
	);
}

function Dashboards() {
	const [dashboard, setDashboard] = useState(0);

	const [dashboard3, setDashboard3] = useState("Loading...");
	const [dashboard3date, setDashboard3date] = useState("Loading...");

	// const [dashboard4, setDashboard4] = useState("Loading...");
	// const [dashboard4date, setDashboard4date] = useState("Loading...");

	function GetDashboards() {
		axios
			.get("https://nodejs-dashboards.onrender.com/")
			.then((response) => {
				setDashboard3(response.data.w);
				setDashboard3date(formatDate(response.data.w_date));

				// setDashboard4(response.data.d);
				// setDashboard4date(formatDate(response.data.d_date));
			});
	}

	useEffect(() => {
		GetDashboards();
		setInterval(GetDashboards, 600000);
	}, []);

	function getClass(id) {
		return dashboard === id ? s.tabSelected : s.tab;
	}

	function getDashboardClass(id) {
		return dashboard === id ? s.dashboard : s.dashboardHidden;
	}

	function getDashboardClass2(id) {
		return dashboard === id ? s.dashboard2 : s.dashboardHidden;
	}

	return (
		<div className={s.Dashboards}>
			<div className={s.topBar}>
				<div onClick={() => setDashboard(0)} className={getClass(0)}>
					ZL3DVX
					<br />
					Aidanfield
				</div>
				<div onClick={() => setDashboard(1)} className={getClass(1)}>
					ZL3DVR
					<br />
					Woolston
				</div>
				<div onClick={() => setDashboard(2)} className={getClass(2)}>
					ZL1BW
					<br />
					Waipara
				</div>
				<div onClick={() => setDashboard(3)} className={getClass(3)}>
					ZL3CAR
					<br />
					840 Repeater
				</div>
				<div onClick={() => setDashboard(4)} className={getClass(4)}>
					ZL3UR
					<br />
					Cave Hill
				</div>
				<div onClick={() => setDashboard(5)} className={getClass(5)}>
					ZL3MX
					<br />
					Waimate
				</div>
			</div>

			<div className={s.container}>
				<iframe
					className={getDashboardClass(0)}
					src="https://zl3dvx.digitalradio.co.nz:51625/"
					title="Aidanfield"
				/>

				<iframe
					className={getDashboardClass(1)}
					src="https://zl3dvr.digitalradio.co.nz:83/"
					title="Woolston"
				/>

				<div className={getDashboardClass2(2)}>
					<div className={s.lastUpdated}>
						Last Updated:
						<br />
						{dashboard3date}
					</div>
					<div dangerouslySetInnerHTML={{ __html: dashboard3 }}></div>
				</div>
				{/* <div className={getDashboardClass2(3)}>
					<div className={s.lastUpdated}>
						Last Updated:
						<br />
						{dashboard4date}
					</div>
					<div dangerouslySetInnerHTML={{ __html: dashboard4 }}></div>
				</div> */}

				<iframe
					className={getDashboardClass(3)}
					src="https://brandmeister.network/?page=device&id=530003"
					title="840"
				/>

				<iframe
					className={getDashboardClass(4)}
					src="https://brandmeister.network/?page=device&id=530022"
					title="Timaru"
				/>

				<iframe
					className={getDashboardClass(5)}
					// src="https://corsproxy.io/?http://dashboard.zl3mx.com:9000"
					src="https://brandmeister.network/?page=device&id=530037"
					title="Waimate"
					id="iframee"
				/>
			</div>
		</div>
	);
}

export default Dashboards;
