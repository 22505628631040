import s from "./Photos.module.css";
import Image from "../components/Image";
import { useEffect, useState } from "react";
import IsMobile from "../components/IsMobile";

function Photos() {
	const [selectedImage, setSelectedImage] = useState(0);
	let isMobile = IsMobile();

	let images = [
		new Image(require("../images/image2.jpg"), "Woolston repeater"),
		new Image(require("../images/image6.jpg"), "Woolston repeater cabinet"),
		new Image(require("../images/image3.jpg"), "Aidanfield repeater"),
		new Image(
			require("../images/image5.jpg"),
			"Aidanfield repeater cabinet"
		),
		new Image(require("../images/image8.jpg"), "Waipara repeater"),
		new Image(require("../images/image4.jpg"), "Waipara repeater cabinet"),
		new Image(
			require("../images/timaru_repeater_site.jpg"),
			"Timaru repeater"
		),
		new Image(
			require("../images/840repeater.jpeg"),
			"840 repeater Marleys Hill"
		),
		new Image(
			require("../images/840marleyshill.jpeg"),
			"840 repeater Marleys Hill"
		),
		new Image(
			require("../images/840marleyshill2.jpeg"),
			"840 repeater Marleys Hill"
		),
		new Image(
			require("../images/marleyszoom.JPG"),
			"Marleys Hill repeaters"
		),
		new Image(require("../images/waimate.jpeg"), "Waimate repeater"),
	];

	function nextImage() {
		let nextIndex = selectedImage + 1;
		if (nextIndex >= images.length) {
			nextIndex = 0;
		}

		setSelectedImage(nextIndex);
		scrollThumbnail(nextIndex);
	}

	let timeout;

	useEffect(() => {
		timeout = setTimeout(nextImage, 3000);
	}, [selectedImage]);

	function scrollThumbnail(i) {
		let width = 116;

		let thumbnails = document.getElementById("thumbnails");

		let scrollPos = width * i - thumbnails.clientWidth / 2 + width / 2;
		scrollPos = Math.max(scrollPos, 0);

		thumbnails.scroll({
			left: scrollPos,
			behavior: "smooth",
		});
	}

	return (
		<div className={s.photos}>
			{images.map((v, i) => {
				return (
					<div
						style={
							selectedImage == i
								? { display: "inline" }
								: { display: "none" }
						}
					>
						{v.getImage(isMobile)}
					</div>
				);
			})}

			<div
				id="thumbnails"
				className={isMobile ? s.thumbnailsMobile : s.thumbnails}
			>
				{images.map((v, i) => {
					return (
						<div
							key={i}
							className={
								i === selectedImage
									? s.selectedThumbnail
									: s.thumbnail
							}
							onClick={() => {
								clearTimeout(timeout);
								setSelectedImage(i);
								scrollThumbnail(i);
							}}
						>
							{v.getThumbnail()}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Photos;
