import { useState } from "react";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Dashboards from "../pages/Dashboards";
import Home from "../pages/Home";
import Maps from "../pages/Maps";
import Photos from "../pages/Photos";
import RepeaterInformation from "../pages/RepeaterInformation";

import s from "./NavBar.module.css";
import DMRLive from "../pages/DMRLive";
import WeeklyChatGroup from "../pages/WeeklyChatGroup";
import DMRExplained from "../pages/DMRExplained";
import RadioSuggestions from "../pages/RadioSuggestions";
import Other from "../pages/Other";
import SystemStatus from "../pages/SystemStatus";
import RadiowaveApp from "../pages/RadiowaveApp";
import DMRForum from "../pages/DMRForum";
import IsMobile from "./IsMobile";

function NavBar(props) {
	const home = 0;
	const dashboards = 1;
	const photos = 2;
	const siteCoverageMaps = 3;
	const repeaterInfomation = 4;
	const contact = 5;
	const about = 6;
	const dmrlive = 7;
	const weeklychatgroup = 8;
	const DMRExp = 9;
	const RadioSug = 10;
	const other = 11;
	const systemStatus = 12;
	const radiowaveApp = 13;
	const dmrForum = 14;

	const [page, setPage] = useState(home);
	const [mobileNavBar, setMobileNavBar] = useState(false);

	let isMobile = IsMobile();

	function getClass(p) {
		if (isMobile) {
			return page === p ? s.itemSelectedMobile : s.itemMobile;
		}

		return page === p ? s.itemSelected : s.item;
	}

	if (isMobile && !mobileNavBar) {
		return (
			<div
				onClick={() => setMobileNavBar(true)}
				className={s.showNavBarBox}
			>
				<div className={s.line}></div>
				<div className={s.line}></div>
				<div className={s.line}></div>
			</div>
		);
	}

	function onTabClick(p, i) {
		setMobileNavBar(false);
		props.onClick(p);
		setPage(i);
	}

	return (
		<div className={s.LeftBar}>
			<div className={s.LeftBarTop}>
				<div
					onClick={() => onTabClick(<Home />, home)}
					className={getClass(home)}
				>
					Home
				</div>
				<div
					onClick={() => onTabClick(<Dashboards />, dashboards)}
					className={getClass(dashboards)}
				>
					Dashboards
				</div>
				<div
					onClick={() => onTabClick(<DMRForum />, dmrForum)}
					className={getClass(dmrForum)}
				>
					DMR Forum
				</div>
				<div
					onClick={() => onTabClick(<SystemStatus />, systemStatus)}
					className={getClass(systemStatus)}
				>
					System Status/News
				</div>
				<div
					onClick={() => onTabClick(<Photos />, photos)}
					className={getClass(photos)}
				>
					Photos
				</div>
				<div
					onClick={() => onTabClick(<Maps />, siteCoverageMaps)}
					className={getClass(siteCoverageMaps)}
				>
					Site Coverage Maps
				</div>
				<div
					onClick={() =>
						onTabClick(<RepeaterInformation />, repeaterInfomation)
					}
					className={getClass(repeaterInfomation)}
				>
					Repeater Information
				</div>
				<div
					onClick={() =>
						window.open("https://hose.brandmeister.network/")
					}
					className={getClass(dmrlive)}
				>
					DMR Live
				</div>
				<div
					onClick={() =>
						onTabClick(<WeeklyChatGroup />, weeklychatgroup)
					}
					className={getClass(weeklychatgroup)}
				>
					Weekly Net
				</div>
				<div
					onClick={() => onTabClick(<DMRExplained />, DMRExp)}
					className={getClass(DMRExp)}
				>
					DMR Explained
				</div>
				<div
					onClick={() => onTabClick(<RadioSuggestions />, RadioSug)}
					className={getClass(RadioSug)}
				>
					Radio Suggestions
				</div>
				<div
					onClick={() => window.open("https://radiowave.nz/")}
					className={getClass(radiowaveApp)}
				>
					Radiowave App
				</div>
				<div
					onClick={() => onTabClick(<Other />, other)}
					className={getClass(other)}
				>
					Other
				</div>
				<div
					onClick={() => onTabClick(<Contact />, contact)}
					className={getClass(contact)}
				>
					Contact
				</div>
				<div
					onClick={() => onTabClick(<About />, about)}
					className={getClass(about)}
				>
					About
				</div>

				<br />
				{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
			</div>
		</div>
	);
}

export default NavBar;
