import { useState } from "react";
import s from "./App.module.css";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";

function App() {
	const [page, setPage] = useState(<Home />);
	const [showBackground, setShowBackground] = useState(false);

	return (
		<div className={s.App}>
			{showBackground ? (
				<div
					onClick={() => {
						setShowBackground(false);
					}}
					className={s.fullscreen}
				></div>
			) : (
				<></>
			)}

			{showBackground ? <></> : <NavBar onClick={(p) => setPage(p)} />}

			{showBackground ? (
				<></>
			) : (
				<div
					style={{
						position: "absolute",
						bottom: 5,
						right: 8,
						fontSize: "18px",
					}}
				>
					Designed by ZL3LEO
				</div>
			)}

			{showBackground ? (
				<></>
			) : (
				<div
					onClick={() => {
						setShowBackground(true);
					}}
					className={s.seeBackgroundImage}
				>
					See background image
				</div>
			)}

			{showBackground ? <></> : page}
		</div>
	);
}

export default App;
