import s from "./DMRForum.module.css";

import { useEffect, useState } from "react";
import loadingcss from "./LoadingAnimation.module.css";
import { database, auth, googleProvider } from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import IsMobile from "../components/IsMobile";
import ThumbsUp from "../components/ThumbsUp";

function DMRForum() {
	const [user] = useAuthState(auth);
	const [hasCallsign, setHasCallsign] = useState(true);
	const [callsign, setCallsign] = useState("");
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);

	let isMobile = IsMobile();

	useEffect(() => {
		database.ref("posts").on("value", (snapshot) => {
			let array = [];
			snapshot.forEach((v) => {
				const data = v.val();
				data.key = v.key;

				let comments_array = [];
				for (let key in data.comments) {
					let comment = data.comments[key];
					comment.key = key;
					comments_array.push(comment);
				}
				data.comments = comments_array;

				array.unshift(data);
			});
			setPosts(array);
			setLoading(false);
		});

		if (!user) {
			return;
		}

		database
			.ref("users/" + user.uid + "/callsign")
			.on("value", (snapshot) => {
				const data = snapshot.val();
				if (data == null) {
					setHasCallsign(false);
				} else {
					setHasCallsign(true);
					setCallsign(data);
				}
			});
	}, [user]);

	if (!hasCallsign) {
		return <CallsignPage user={user} />;
	}

	if (loading) {
		return (
			<div className={isMobile ? s.HomeMobile : s.Home}>
				<div className={s.title}>DMR Forum</div>
				<div class={loadingcss.loading}></div>
			</div>
		);
	}

	if (user && user.emailVerified) {
		return (
			<Page
				posts={posts}
				user={user}
				callsign={callsign}
				isMobile={isMobile}
			/>
		);
	}

	return (
		<LoggedOutPage
			signedIn={false}
			isMobile={isMobile}
			posts={posts}
			callsign={callsign}
			signOut={() => auth.signOut()}
		/>
	);
}

function Page(props) {
	const [newPost, setNewPost] = useState(false);
	const [editPostData, setEditPostData] = useState(null);

	if (editPostData) {
		return (
			<EditPostPage
				data={editPostData}
				close={() => setEditPostData(null)}
				isMobile={props.isMobile}
			/>
		);
	}

	if (newPost) {
		return (
			<CreatePostPage
				user={props.user}
				callsign={props.callsign}
				close={() => setNewPost(false)}
				isMobile={props.isMobile}
			/>
		);
	}

	return (
		<div className={props.isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>DMR Forum</div>
			<br />
			<div className={s.text}>
				Welcome {props.callsign} to the Canterbury DMR forum. This forum
				is for discussing DMR radio related topics.
			</div>
			<br />
			<div className={s.buttons}>
				<div className={s.button} onClick={() => setNewPost(true)}>
					Create Post
				</div>
				<div
					style={{ marginLeft: "auto" }}
					className={s.button}
					onClick={() => auth.signOut()}
				>
					Sign Out
				</div>
			</div>
			{props.posts.map((v, i) => {
				return (
					<Post
						key={i}
						callsign={props.callsign}
						uid={props.user.uid}
						post={v}
						editPost={(data) => {
							setEditPostData(data);
						}}
					/>
				);
			})}
			{props.isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

function EditPostPage(props) {
	const [title, setTitle] = useState(props.data.title);
	const [message, setMessage] = useState(props.data.message);

	useEffect(() => {
		addTextAreaAutoResize();
	}, []);

	return (
		<div className={props.isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Edit Post</div>
			<br />
			<div className={s.buttons}>
				<div className={s.button} onClick={() => props.close()}>
					Cancel
				</div>
				<div
					className={s.button + " " + s.postButton}
					onClick={() => {
						if (UpdatePost(title, message, props.data.messageId)) {
							props.close();
						}
					}}
				>
					Update
				</div>
			</div>
			<br />
			<div className={s.createPostSmallText}>Title</div>
			<input
				className={s.createPostTitleInput}
				value={title}
				onChange={(e) => {
					setTitle(e.target.value);
				}}
			/>
			<br />
			<br />
			<div className={s.createPostSmallText}>Message</div>
			<textarea
				className={s.editMessageInput}
				value={message}
				onChange={(e) => {
					setMessage(e.target.value);
				}}
			/>
		</div>
	);
}

function LoggedOutPage(props) {
	const [logInBox, setLogInBox] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const signInWithGoogle = () => {
		auth.signInWithPopup(googleProvider).catch((e) => alert(e));
	};

	const sendEmail = (userCredential) => {
		userCredential.user.sendEmailVerification();
		auth.signOut();
		setLogInBox(false);
		alert("Email verification sent.");
	};

	const signInWithEmail = () => {
		auth.signInWithEmailAndPassword(email, password)
			.then((userCredential) => {
				if (!userCredential.user.emailVerified) {
					sendEmail(userCredential);
				}
			})
			.catch((e) => alert(e));
	};

	const createAccountWithEmail = () => {
		auth.createUserWithEmailAndPassword(email, password)
			.then((userCredential) => {
				sendEmail(userCredential);
			})
			.catch((e) => alert(e));
	};

	return (
		<div className={props.isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>DMR Forum</div>
			<br />

			{logInBox ? (
				<div>
					<div
						className={s.button}
						onClick={() => setLogInBox(false)}
					>
						Back
					</div>
					<br />
					<div>Email</div>
					<input
						className={s.createPostTitleInput}
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
					<br />
					<br />
					<div>Password</div>
					<input
						className={s.createPostTitleInput}
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>
					<br />
					<br />
					<div style={{ display: "flex" }}>
						<div
							className={s.button}
							onClick={() => createAccountWithEmail()}
						>
							Create Account
						</div>
						<br />
						<br />
						<div
							className={s.button + " " + s.postButton}
							onClick={() => signInWithEmail()}
						>
							Sign In
						</div>
					</div>
				</div>
			) : (
				<div>
					<div style={{ display: "flex" }}>
						<div className={s.button} onClick={signInWithGoogle}>
							Sign in with Google
						</div>
						<div
							className={s.button + " " + s.signInWithEmailButton}
							onClick={() => setLogInBox(true)}
						>
							Sign in with email
						</div>
					</div>

					{props.posts.map((v, i) => {
						return <LoggedOutPost post={v} />;
					})}
				</div>
			)}

			{props.isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

function CreatePostPage(props) {
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");

	addTextAreaAutoResize();

	return (
		<div className={props.isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Create Post</div>
			<br />
			<div className={s.buttons}>
				<div className={s.button} onClick={() => props.close()}>
					Cancel
				</div>
				<div
					className={s.button + " " + s.postButton}
					onClick={() => {
						if (
							CreatePost(
								title,
								message,
								props.callsign,
								props.user
							)
						) {
							props.close();
						}
					}}
				>
					Post
				</div>
			</div>
			<br />
			<div className={s.createPostSmallText}>Title</div>
			<input
				className={s.createPostTitleInput}
				value={title}
				onChange={(e) => {
					setTitle(e.target.value);
				}}
			/>
			<br />
			<br />
			<div className={s.createPostSmallText}>Message</div>
			<textarea
				className={s.editMessageInput}
				value={message}
				onChange={(e) => {
					setMessage(e.target.value);
				}}
			/>
		</div>
	);
}

function CreatePost(title, message, callsign, user) {
	if (title.length < 5) {
		alert("Title must be at least 5 characters long");
		return false;
	}

	if (message.length < 10) {
		alert("Message Must be at least 10 characters long");
		return false;
	}

	database
		.ref("posts")
		.push({
			sender: user.uid,
			title: title,
			message: message,
			callsign: callsign,
		})
		.catch((e) => {
			alert(e);
			return false;
		});

	return true;
}

function UpdatePost(title, message, messageId) {
	if (title.length < 5) {
		alert("Title must be at least 5 characters long");
		return false;
	}

	if (message.length < 10) {
		alert("Message Must be at least 10 characters long");
		return false;
	}

	database
		.ref("posts/" + messageId)
		.update({
			title: title,
			message: message,
		})
		.catch((e) => {
			alert(e);
			return false;
		});

	return true;
}

function Post(props) {
	const [commentBar, setCommentBar] = useState(false);

	return (
		<div>
			<div className={s.post}>
				<div className={s.postTitle}>{props.post.title}</div>
				<br />
				<div
					className={s.postMessage}
					dangerouslySetInnerHTML={{ __html: props.post.message }}
				></div>
				<br />
				<div className={s.postCallsign}>{props.post.callsign}</div>
				<div className={s.postTime}>
					{getFormattedTime(props.post.date)}
				</div>
				<br />
				<div style={{ display: "flex" }}>
					<Reactions postId={props.post.key} uid={props.uid} />

					<div
						className={s.replyPostText}
						onClick={() => setCommentBar(true)}
					>
						Reply
					</div>

					{props.post.sender === props.uid ? (
						<div
							className={s.editPostText}
							onClick={() =>
								props.editPost({
									message: props.post.message,
									title: props.post.title,
									messageId: props.post.key,
								})
							}
						>
							Edit
						</div>
					) : (
						<></>
					)}
				</div>
			</div>

			{props.post.comments.map((v, i) => {
				return (
					<Comment
						key={i}
						callsign={v.callsign}
						message={v.message}
						sender={v.sender}
						commentId={v.key}
						messageId={props.post.key}
						uid={props.uid}
					/>
				);
			})}

			<AddCommentBar
				visible={commentBar}
				close={() => setCommentBar(false)}
				callsign={props.callsign}
				uid={props.uid}
				messageId={props.post.key}
			/>
		</div>
	);
}

function LoggedOutPost(props) {
	return (
		<div>
			<div className={s.post}>
				<div className={s.postTitle}>{props.post.title}</div>
				<br />
				<div
					className={s.postMessage}
					dangerouslySetInnerHTML={{ __html: props.post.message }}
				></div>
				<br />
				<div className={s.postCallsign}>{props.post.callsign}</div>
				<div className={s.postTime}>
					{getFormattedTime(props.post.date)}
				</div>
				<br />
				<Reactions postId={props.post.key} />
			</div>

			{props.post.comments.map((v, i) => {
				return (
					<Comment
						key={i}
						callsign={v.callsign}
						message={v.message}
					/>
				);
			})}
		</div>
	);
}

function Reactions(props) {
	const [likes, setLikes] = useState(0);
	const [dislikes, setDislikes] = useState(0);
	const [happy, setHappy] = useState(0);
	const [smiley, setSmiley] = useState(0);
	const [face, setFace] = useState(0);
	const [sad, setSad] = useState(0);

	const [liked, setLiked] = useState(-1);

	useEffect(() => {
		database
			.ref("posts/" + props.postId + "/likes")
			.on("value", (snapshot) => {
				let count = new Array(6).fill(0);

				snapshot.forEach((v) => {
					if (v.key === props.uid) {
						setLiked(v.val());
					}

					try {
						count[v.val()]++;
					} catch (e) {}
				});

				setLikes(count[0]);
				setDislikes(count[1]);
				setHappy(count[2]);
				setSmiley(count[3]);
				setFace(count[4]);
				setSad(count[5]);
			});
	}, []);

	const addLike = (id) => {
		if (!props.uid) {
			return;
		}

		database
			.ref("posts/" + props.postId + "/likes/" + props.uid)
			.set(id)
			.catch((e) => {
				alert(e);
			});
	};

	return (
		<div className={s.reactions}>
			<ThumbsUp
				src={require("../images/thumbsup.png")}
				uid={props.uid}
				liked={liked === 0}
				number={likes}
				onClick={() => addLike(0)}
			/>
			<ThumbsUp
				src={require("../images/thumbsdown.png")}
				uid={props.uid}
				liked={liked === 1}
				number={dislikes}
				onClick={() => addLike(1)}
			/>
			<ThumbsUp
				src={require("../images/happyface.png")}
				uid={props.uid}
				liked={liked === 2}
				number={happy}
				onClick={() => addLike(2)}
			/>
			<ThumbsUp
				src={require("../images/smileyface.png")}
				uid={props.uid}
				liked={liked === 3}
				number={smiley}
				onClick={() => addLike(3)}
			/>
			<ThumbsUp
				src={require("../images/face.png")}
				uid={props.uid}
				liked={liked === 4}
				number={face}
				onClick={() => addLike(4)}
			/>
			<ThumbsUp
				src={require("../images/sadface.png")}
				uid={props.uid}
				liked={liked === 5}
				number={sad}
				onClick={() => addLike(5)}
			/>
		</div>
	);
}

function getFormattedTime(ms) {
	if (!ms) {
		return "";
	}

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	let d = new Date(0);
	d.setUTCMilliseconds(ms);

	let month = d.getMonth();
	let day = d.getDate();
	let hour = d.getHours();
	let min = d.getMinutes();

	let ampm = hour >= 12 ? "pm" : "am";
	day = day < 10 ? "0" + day : day;
	hour = hour % 12;
	hour = hour ? hour : 12; // the hour '0' should be '12'
	min = min < 10 ? "0" + min : min;
	return `${months[month]} ${day}, ${hour}:${min} ${ampm}`;
}

function Comment(props) {
	return (
		<div className={s.comment}>
			<div style={{ display: "flex" }}>
				<div>{props.callsign}</div>
				{props.sender === props.uid && props.uid ? (
					<div
						className={s.deleteCommentText}
						onClick={() => {
							if (
								window.confirm(
									"Do you want to delete the comment?"
								)
							) {
								DeleteComment(props.messageId, props.commentId);
							}
						}}
					>
						Delete
					</div>
				) : (
					<></>
				)}
			</div>
			<div>{props.message}</div>
		</div>
	);
}

function DeleteComment(postId, commentId) {
	database.ref("posts/" + postId + "/comments/" + commentId).remove();
}

function addTextAreaAutoResize() {
	const tx = document.getElementsByTagName("textarea");
	for (let i = 0; i < tx.length; i++) {
		tx[i].addEventListener("input", OnInput, false);
	}

	function OnInput() {
		for (let i = 0; i < tx.length; i++) {
			tx[i].style.height = 0;
			tx[i].style.height = tx[i].scrollHeight + "px";
		}
	}

	OnInput();
}

function AddCommentBar(props) {
	const [message, setMessage] = useState("");

	addTextAreaAutoResize();

	if (props.visible) {
		return (
			<div className={s.comment}>
				<textarea
					className={s.editMessageInput}
					value={message}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
				/>

				<div style={{ display: "flex" }}>
					<div
						className={s.cancelCommentText}
						onClick={() => props.close()}
					>
						Cancel
					</div>
					<div
						className={s.addCommentText}
						onClick={() => {
							if (
								AddComment(
									props.callsign,
									message,
									props.uid,
									props.messageId
								)
							) {
								setMessage("");
								props.close();
							}
						}}
					>
						Comment
					</div>
				</div>
			</div>
		);
	}

	return <></>;
}

function AddComment(callsign, message, uid, messageId) {
	if (message.length < 1) {
		return false;
	}

	database
		.ref("posts/" + messageId + "/comments")
		.push({
			sender: uid,
			message: message,
			callsign: callsign,
		})
		.catch((e) => {
			alert(e);
			return false;
		});

	return true;
}

function CallsignPage(props) {
	const [text, setText] = useState("");

	return (
		<div className={props.isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Enter Your Callsign</div>
			<br />
			<div className={s.callsignContainer}>
				<input
					className={s.callsignInput}
					value={text}
					onChange={(e) => {
						setText(e.target.value);
					}}
				/>
				<div
					className={s.callsignButton}
					onClick={() =>
						UpdateCallsign(
							text.replace(/\s+/g, "").toUpperCase(),
							props.user
						)
					}
				>
					Done
				</div>
			</div>
		</div>
	);
}

function UpdateCallsign(callsign, user) {
	database
		.ref("users/" + user.uid + "/callsign")
		.set(callsign)
		.catch((e) => {
			alert(e);
		});
}

export default DMRForum;
