import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function About() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>About</div>
			<br />
			Doug ZL3DMH has had an interest in radio since his high school days,
			starting out in 26 Mhz CB radio and progressing on to be an amateur
			radio operator in 1992. Doug set up the first Brandmeister
			multi-mode digital repeater in New Zealand in 2017. Doug likes Tait
			products and uses TM9300, TM9100, TP9300, TP9400 and TP9500 on the
			DMR + P25 networks. Tait is a locally made icon in New Zealand.
			<br />
			<br />
			Bruce ZL1BW has had an interest in radio since high school, starting
			out in CB radio, worked in the RF industry for many years and
			recently gained his Amateur radio license in 2022. Since Doug and
			Bruce met on the local repeaters they have expanded the DMR network
			across Canterbury to 4 repeater sites, continue to improve and
			expand the DMR network.
			<br />
			<br />
			Leo ZL3LEO gained his license in 2023 after learning about packet
			radio and repeaters on the ISS. He designed and wrote the Canterbury
			DMR website and developed an app to send messages over simplex. Leo
			has put a lot of hard work and time to develop the website for us
			all to use. The website is a great resource to DMR users of NZ.
			<br />
			<br />
			73's
			<br />
			<br />
			ZL3DMH, ZL1BW and ZL3LEO
			<br />
			<br />
			<img className={s.image} src={require("../images/radio.jpg")} />
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default About;
