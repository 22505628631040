import { useEffect, useState } from "react";
import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function WeeklyChatGroup() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Weekly Net</div>
			<br />
			<br />
			<CountDown />
			<br />
			<br />
			Last Updated 5 September 2023
			<br />
			<br />
			We are running a DMR net on Talk Group 5303 for anyone to join in on
			Friday evenings, kicks off from 9:30pm (NZ Standard time) until late
			and is hosted by Noel ZL4OW.
			<br />
			<br />
			Grab a Cupa Tea and a Biscuit and tune in for some interesting
			conversations covering a large array of topics. Feel free to ask for
			any advice if you are having DMR problems, meet new hams, everyone
			is here to help.
			<br />
			<br />
			Chat guide lines, Pass it to the next person, feel free to have a
			few overs to the same person, keep in mind there are other people
			who would like to chip in, if you don't know who to pass to next
			send it back to Noel ZL4OW and he will keep things alive.
			<br />
			<br />
			Noel's Tips - Keep an eye on the dashboards if you want to catch a
			call sign. Count to 5 take a breath and then hit the PTT button as
			DMR is a bit more difficult to get a breaker in.
			<br />
			<br />
			<br />
			<img
				className={s.image}
				style={{ width: "25%" }}
				src={require("../images/TYT-MD-390UV.jpg")}
			/>
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

function CountDown() {
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const [netStarted, setNetStarted] = useState(false);

	function getSecondsUntilNet() {
		var nowDate = new Date();
		var day = 5;
		var netDate = new Date(
			nowDate.getFullYear(),
			nowDate.getMonth(),
			nowDate.getDate(),
			21,
			30,
			0
		);

		var diff = parseInt((netDate.getTime() - nowDate.getTime()) / 1000);
		var curday = day - nowDate.getDay();

		if (diff <= 0 && diff >= -60 * 60) {
			setNetStarted(true);
		} else {
			setNetStarted(false);
		}

		diff += curday * 86400;

		if (diff <= 0) {
			diff += 86400 * 7;
		}

		return diff;
	}

	function updateTime(secs) {
		setDays(Math.floor(secs / 86400));
		secs %= 86400;
		setHours(Math.floor(secs / 3600));
		secs %= 3600;
		setMinutes(Math.floor(secs / 60));
		secs %= 60;
		setSeconds(secs);
	}

	useEffect(() => {
		let interval = setInterval(() => {
			let seconds = getSecondsUntilNet();
			updateTime(seconds);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	if (netStarted) {
		return (
			<div className={s.netStartedText + " " + s.blink}>
				🎉 The net started 🎉
			</div>
		);
	}

	return (
		<div>
			<div className={s.countdownTitle}>The next net is starting in:</div>
			<br />
			<div className={s.countdown}>
				<div className={s.countdownBox}>
					<div className={s.countdownNumber}>{days}</div>
					<div className={s.countdownText}>days</div>
				</div>
				<div className={s.countdownBox}>
					<div className={s.countdownNumber}>{hours}</div>
					<div className={s.countdownText}>hours</div>
				</div>
				<div className={s.countdownBox}>
					<div className={s.countdownNumber}>{minutes}</div>
					<div className={s.countdownText}>minutes</div>
				</div>
				<div className={s.countdownBox}>
					<div className={s.countdownNumber}>{seconds}</div>
					<div className={s.countdownText}>seconds</div>
				</div>
			</div>
		</div>
	);
}

export default WeeklyChatGroup;
