import s from "./Other.module.css";
import ReactAudioPlayer from "react-audio-player";
import last_broadcast from "../audio/last_broadcast.m4a";
import interview from "../audio/interview.mp3";
import IsMobile from "../components/IsMobile";

function Home() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Canterbury Mountain Radio</div>
			<br />
			<br />
			After 55 years of service on the 30th of June 2023, the Canterbury
			Mountain Radio Service did its last broadcast. They sighted new
			technologies, lack of younger people taking it on and the radios not
			being hired very often as reasons for the closing of the service.
			<br />
			<br />
			The secretary Ian Gardiner is a good friend of mine and it is sad to
			see this service go. For those unaware the Mountain Radio Service
			broadcast the weather forecast 2 times a day and assisted in search
			and recuse operations.
			<br />
			<br />
			<br />
			Please take the time to listen to the last broadcast I recorded on
			the 30 June at 7:30pm
			<br />
			<br />
			<ReactAudioPlayer src={last_broadcast} controls />
			<br />
			<br />
			<br />
			Here is an interview with Ian Gardiner and RNZ
			<br />
			<br />
			<ReactAudioPlayer src={interview} controls />
			<br />
			<br />
			<br />
			<br />
			By ZL1BW
			<br />
			<br />
			<img
				className={s.image}
				src={require("../images/mountainradio.jpg")}
			/>
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default Home;
