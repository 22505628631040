import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function RadiowaveApp() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Radiowave App</div>
			<br />
			<br />
			A chat app that doesn't use the internet. The app is only for
			android and works on UHF/VHF FM Simplex.
			<br /> <br />
			<img
				style={{ height: "500px" }}
				src={require("../images/screenshot-radiowave.jpeg")}
			/>
			<br /> <br />
			<div style={{ fontSize: "26px" }}>How to use the app:</div>
			<br />
			You need a cable that connects to your phone and radio. This cable
			(APRS-K1) works on a TYT MD-UV390 and an AnyTone 868:
			<br /> <br />
			<img
				className={s.half_image}
				src={require("../images/cable.jpeg")}
			/>
			<br />
			<br />
			You need VOX on your radio and turn the volume up on your radio and
			phone.
			<br />
			<br />
			<div style={{ fontSize: "26px" }}>How the app works:</div>
			<br />
			When a message is sent, the phone encodes the message onto audio
			using FSK and plays it. The sound is transmitted over radio. When
			the sound is received, the phone decodes it. When the message is
			decoded successfully, it will send a confirmation message back. When
			the confirmation message is received, the message will say
			delivered. If the message failes to send, long pressing on the
			message will show options and one of the options will be to resend.
			<br /> <br />
			<div style={{ fontSize: "26px" }}>What's new in version 1.1?</div>
			<br />
			Better FSK algorithm. <br />
			Sends messages at around 800 bits per second. <br />
			Sending messages and confirmation is more reliable. <br />
			Length of the bit clock can be changed. <br />
			Fixed some issues that caused the app to crash. <br />
			Phone stays turned on while on the app. <br />
			Few other small changes.
			<br /> <br />
			<div style={{ fontSize: "26px" }}>Download:</div>
			<br />I have spent hours trying to get the app working so if you
			would like to dontate, please consider purchasing the app in the{" "}
			<a
				className={s.link}
				href="https://play.google.com/store/apps/details?id=com.radiowave.chat"
			>
				play store
			</a>
			. By ZL3LEO
			<br /> <br />
			If you want to install it for free, download the apk file on your
			phone and run it to install the app.
			<br /> <br />
			Download:{" "}
			<a
				className={s.link}
				target="_blank"
				href="https://drive.google.com/file/d/1mpyUxRueycIdkziZVBAUpjRvJoYo7r0Z/view"
			>
				v1.1 - Latest Version
			</a>
			<br /> <br />
			Download:{" "}
			<a
				className={s.link}
				target="_blank"
				href="https://drive.google.com/file/d/1du6E_PnCLXYmvJNSY_pUL__6g--CnJIo/view"
			>
				v1.0
			</a>
			<br /> <br />
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default RadiowaveApp;
