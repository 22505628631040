import { useEffect, useState } from "react";
import s from "./Home.module.css";
import loadingcss from "./LoadingAnimation.module.css";
import { database, auth, googleProvider } from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import IsMobile from "../components/IsMobile";

function SystemStatus() {
	const [user] = useAuthState(auth);
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>System Status/News</div>
			<br />
			{user ? (
				<EditMessage isMobile={isMobile} />
			) : (
				<SignIn isMobile={isMobile} />
			)}
		</div>
	);
}

function SignIn(props) {
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(true);
	const [lastUpdate, setLastUpdate] = useState(0);

	useEffect(() => {
		let ref = database.ref("messages");
		ref.on("value", (snapshot) => {
			const data = snapshot.val();
			setMessage(data.systemStatus);
			setLastUpdate(data.lastUpdate);
			setLoading(false);
		});
	}, []);

	const signInWithGoogle = () => {
		auth.signInWithPopup(googleProvider).catch((e) => {});
	};

	return (
		<div>
			{loading ? (
				<div class={loadingcss.loading}></div>
			) : (
				<div>
					Last updated: {formatDate(lastUpdate)}
					<br />
					<br />
					<br />
					<div style={{ textAlign: "left", whiteSpace: "pre-line" }}>
						{message}
					</div>
				</div>
			)}
			<br />
			<br />
			<div
				className={`${s.button} ${s.loginButton}`}
				onClick={signInWithGoogle}
			>
				Log In
			</div>
			{props.isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

function Update(message) {
	database
		.ref("messages")
		.set({
			systemStatus: message,
			lastUpdate: Date.now(),
		})
		.catch((e) => {
			alert(e);
		});
}

function EditMessage(props) {
	const [message, setMessage] = useState("");
	const [lastUpdate, setLastUpdate] = useState(0);
	const [showUpdate, setShowUpdpate] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let ref = database.ref("messages");
		ref.on("value", (snapshot) => {
			const data = snapshot.val();
			setMessage(data.systemStatus);
			setLastUpdate(data.lastUpdate);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		OnInput();
	}, [loading]);

	const tx = document.getElementsByTagName("textarea");
	for (let i = 0; i < tx.length; i++) {
		tx[i].addEventListener("input", OnInput, false);
	}

	function OnInput() {
		for (let i = 0; i < tx.length; i++) {
			tx[i].style.height = 0;
			tx[i].style.height = tx[i].scrollHeight + "px";
		}
	}

	function LogOut() {
		auth.signOut();
	}

	return (
		<div>
			{loading ? (
				<div class={loadingcss.loading}></div>
			) : (
				<div>
					Last updated: {formatDate(lastUpdate)}
					<br />
					<br />
					<br />
					<textarea
						className={s.editMessageInput}
						value={message}
						onChange={(e) => {
							setMessage(e.target.value);
							setShowUpdpate(true);
						}}
					/>
				</div>
			)}

			<br />
			<br />
			{showUpdate ? (
				<center>
					<div
						className={s.button}
						onClick={() => {
							Update(message);
							setShowUpdpate(false);
						}}
					>
						Update
					</div>
				</center>
			) : (
				<></>
			)}
			<div className={`${s.button} ${s.loginButton}`} onClick={LogOut}>
				Log Out
			</div>
			{props.isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

function formatDate(d) {
	var months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	let date = new Date(d);
	let dateArr = date.toLocaleDateString("en-US").split("/");

	if (dateArr.length < 3) {
		return "Error";
	}

	return (
		months[Number(dateArr[0]) - 1] +
		" " +
		dateArr[1] +
		" " +
		dateArr[2] +
		" " +
		date.toLocaleTimeString()
	);
}

export default SystemStatus;
