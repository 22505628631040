import s from "./ThumbsUp.module.css";

function ThumbsUp(props) {
	return (
		<div className={props.liked ? s.ThumbsUpLiked : s.ThumbsUp}>
			{props.number > 0 ? (
				<div className={s.number}>{props.number}</div>
			) : (
				<></>
			)}
			<img
				className={props.uid ? s.image : s.loggedOutImage}
				src={props.src}
				onClick={() => props.onClick()}
			/>
		</div>
	);
}

export default ThumbsUp;
