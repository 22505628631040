import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function DMRExplained() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>DMR Explained</div>
			<br />
			<br />
			This is a good video from BridgeCom Systems, Inc that explains the
			basics of DMR
			<br />
			<br />
			<iframe
				width="100%"
				height="56.25%%"
				style={{ border: "none" }}
				title="DMR Live"
				src="https://www.youtube.com/embed/-3HGGieNUx8"
			></iframe>
			<br />
			<br />
			For More imformation about DMR click{" "}
			<a
				className={s.link}
				rel="noreferrer"
				href="https://www.jeffreykopcak.com/2017/05/10/dmr-in-amateur-radio-terminology/"
				target="_blank"
			>
				here
			</a>
			.
			<br />
			<br />
			<br />
			<img
				alt=""
				className={s.image}
				src={require("../images/image9.jpg")}
			/>
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default DMRExplained;
