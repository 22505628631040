import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function Contact() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Contact</div>
			<br /> For any Canterbury DMR network related questions please
			please visit the{" "}
			<a
				className={s.link}
				target="_blank"
				href="https://www.facebook.com/share/1oK9HdAyoH5EwQtF/?mibextid=A7sQZp"
			>
				ZL5303 facebook page
			</a>
			<br />
			<br />
			If you require information on setting up your radio please visit the
			"Repeater Information" tab, this gives you the basics for your radio
			settings.
			<br />
			<br />
			<img className={s.image} src={require("../images/radio2.JPG")} />
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default Contact;
