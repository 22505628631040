import IsMobile from "../components/IsMobile";
import s from "./Home.module.css";

function RadioSuggestions() {
	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>Radio Suggestions</div>
			<br />
			<br />
			There are many options for DMR radios, this is just a small
			suggestions for DMR radios available in NZ. There is no perfect
			radio out there, they all have their good points and not so good
			points, but for general DMR operation they all work very well. It's
			all personal preference.
			<br />
			<br />
			<strong>Portable</strong>
			<br />
			<br />
			The Chinese designed TYT MD380/MD390 series of radio are a good
			basic portable radio that work well on DMR and have nice audio
			quality. The Anytone AT-D878UVII Plus is a portable radio at the
			higher end of the Chinese DMR radios. The Tait TP9355/TP9360 series
			are a good durable radio along with the Hytera PD-782/PD-662/PD-682
			radios. These are a commercial radios and come in at a much higher
			price point. These commercial radios pop up from time to time used.
			The commercial radios need a programming cable. The Chinese ones are
			more open and some have different firmware available to enhance for
			amateur radio use.
			<br />
			<br />
			<strong>Mobile/Base</strong>
			<br />
			<br />
			The Chinese TYT MD-9600 is another good radio with good audio
			quality. The Tait TM9355 and Hytera MD-782 are good radios that work
			well on DMR. Tait and Hytera are commercial radios and come in at a
			higher price point. Hytera is a large manufacture of high quality
			radios from China. Tait is a New Zealand icon that has been
			designing and building high end radio equipment for many years.
			<br />
			<br />
			<br />
			<img className={s.image} src={require("../images/radios1.jpg")} />
			<br />
			<img className={s.image} src={require("../images/radios2.jpg")} />
			<br />
			<img className={s.image} src={require("../images/radios3.jpg")} />
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default RadioSuggestions;
