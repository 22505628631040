import { useEffect, useState } from "react";

function IsMobile() {
	const [isMobile, setIsMobile] = useState(
		window.innerWidth <= window.innerHeight
	);

	function handleWindowSizeChange() {
		setIsMobile(window.innerWidth <= window.innerHeight);
	}

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	return isMobile;
}

export default IsMobile;
