import { useEffect, useState } from "react";
import s from "./Other.module.css";
import loadingcss from "./LoadingAnimation.module.css";
import axios from "axios";
import IsMobile from "../components/IsMobile";

function ISS() {
	const [passes, setPasses] = useState([]);
	const [lat, setLat] = useState(0);
	const [lon, setLon] = useState(0);
	const [loading, setLoading] = useState(true);

	let prevDay = -1;

	function prevDaySpacing(day) {
		if (day !== prevDay) {
			prevDay = day;
			return <br />;
		}

		return <div></div>;
	}

	function getPasses(la, lo) {
		axios
			.get("https://iss-passes.onrender.com?lat=" + la + "&lon=" + lo)
			.then((response) => {
				setPasses(response.data.passes);
				setLoading(false);
			});
	}

	useEffect(() => {
		let localStorage_lat = localStorage.getItem("lat");
		let localStorage_lon = localStorage.getItem("lon");

		if (localStorage_lat && localStorage_lon) {
			setLat(localStorage_lat);
			setLon(localStorage_lon);

			getPasses(localStorage_lat, localStorage_lon);
		} else {
			setLat(-43.53);
			setLon(172.62);

			setLoading(false);
		}
	}, []);

	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	function getClass(index) {
		if (index % 2 === 0) {
			return s.row2;
		}

		return s.row1;
	}

	let isMobile = IsMobile();

	return (
		<div className={isMobile ? s.HomeMobile : s.Home}>
			<div className={s.title}>ISS Passes</div>
			<br />
			<br />
			<div>
				Location: {lat}, {lon}
			</div>
			<br />
			<div className={s.table}>
				<div className={s.table_titles}>
					<div>Start Time</div>
					<div>Compass</div>
					<div>Elevation</div>
				</div>

				{passes.map((val, index) => {
					var d = new Date(val.startUTC * 1000);

					return (
						<div>
							{prevDaySpacing(d.getDate())}

							<div className={getClass(index)} key={index}>
								<div>
									{days[d.getDay()]} {months[d.getMonth()]}{" "}
									{("0" + d.getDate()).slice(-2)}{" "}
									{("0" + d.getHours()).slice(-2)}:
									{("0" + d.getMinutes()).slice(-2)}
								</div>
								<div>{val.maxAzCompass}</div>
								<div>{Math.round(val.maxEl)}°</div>
							</div>
						</div>
					);
				})}
			</div>
			{loading ? (
				<div>
					<div class={loadingcss.loading}></div> <br /> This could
					take up to a minute...
				</div>
			) : (
				<div></div>
			)}
			<br />
			<br />
			Start time is when the ISS will be at the horizon. Compass is the
			direction of the iss at its highest elevation. Elevation is how many
			degrees from the horizon at its heighest elevation.
			<br />
			<br />
			You can get your latitude and longitude by right clicking on google
			maps on your location.
			<br />
			<br />
			<div>
				<div className={s.latloninputs}>
					<div>Latitude</div>
					<input
						type="number"
						value={lat}
						onChange={(e) => setLat(e.target.value)}
					/>
				</div>

				<div style={{ height: "4px" }}></div>

				<div className={s.latloninputs}>
					<div>Longitude</div>
					<input
						type="number"
						value={lon}
						onChange={(e) => setLon(e.target.value)}
					/>
				</div>
				<br />
				{loading ? (
					<div></div>
				) : (
					<div className={s.latloninputs}>
						<div
							className={s.button}
							onClick={() => {
								localStorage.setItem("lat", lat);
								localStorage.setItem("lon", lon);

								getPasses(lat, lon);
								setLoading(true);
							}}
						>
							Update
						</div>
					</div>
				)}
			</div>
			{isMobile ? <div className={s.bottomForScroll}></div> : <></>}
		</div>
	);
}

export default ISS;
